<template>
  <div>
    <div v-if="otpInfo">
      <h2 style="font-size: 1.5rem;">Request One Time Password</h2>
      <div style="margin-top: 3rem;">
        Please select where to send the OTP
      </div>
      <div style="display: flex; flex-direction: column; padding: 1rem; row-gap: 0.5rem;">
        <label v-if="otpInfo.mfaPhone && otpInfo.mfaPhoneSupported">
          <input type="radio" value="sms"  v-model="channel">
          Text to {{otpInfo.mfaPhone}}
        </label>
        <label v-if="otpInfo.mfaEmail && otpInfo.mfaEmailSupported">
          <input type="radio" value="email" v-model="channel">
          Email to {{otpInfo.mfaEmail}}
        </label>
      </div>
      <div style="margin-top: 2rem;">
        <button class="button is-success" @click="save">Send OTP</button>
      </div>

      <div v-if="otpInfo && !otpInfo.mfaEnrolled" style="margin-top: 4rem;">
        <router-link :to="{name:'otpEnrollment'}">Change my phone/email</router-link>
      </div>


    </div>
  </div>
</template>
<script>

export default {
  components: {

  },
  data() {
    return {
      otpInfo: null,
      channel: null
    }
  },
  mounted() {
    this.channel = 'sms';
    let otpInfoString = sessionStorage.getItem('otp_info');
    if (otpInfoString) {
      this.otpInfo = JSON.parse(otpInfoString);
    }
  },
  methods: {
    async save() {
      this.$store.commit("SET_GLOBAL_NOTIFICATION");
      this.$store.commit("SET_LOADING", true);
      try {
        let payload = {otpKey: this.otpInfo.otpKey, useEmail: (this.channel == 'email'), useSMS: (this.channel == 'sms')}
        await this.$store.dispatch("requestOTP", payload);
        this.otpInfo.email = this.email;
        this.otpInfo.phone = this.phone;
        this.otpInfo.channel = this.channel;
        sessionStorage.setItem('otp_info', JSON.stringify(this.otpInfo));
        this.$router.push({ name: 'otpVerification', params: { instanceId: this.$route.params.instanceId } });
      } catch (e) {
        this.$store.commit("SET_GLOBAL_NOTIFICATION", {
          showNotification: true,
          notificationMessage: `Error Saving Password; ${e}`,
          notificationType: "error"
        });
      } finally {
        this.$store.commit("SET_LOADING", false);
      }
    }
  }
};
</script>
